<template>
  <div>
    <c-tab
      type="pagetop"
      align="left"
      :dense="true"
      :inlineLabel="true"
      :tabItems.sync="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'langManage',
  data() {
    return {
      editable: true,
      tab: 'langLabel',
      tabItems: [
        { name: 'langLabel', icon: 'text_fields', label: '라벨 관리', component: () => import(`${'./langLabel.vue'}`) },
        { name: 'langMessage', icon: 'chat', label: '메시지 관리', component: () => import(`${'./langMessage.vue'}`) },
        { name: 'labelUpload', icon: 'cloud_upload', label: '라벨 일괄업로드', component: () => import(`${'./labelUpload.vue'}`) },
        { name: 'msgUpload', icon: 'cloud_upload', label: '메시지 일괄업로드', component: () => import(`${'./msgUpload.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
